
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBRow, MDBCol } from 'mdbreact'
import Showdown from 'showdown'

class ColumnAlternatingPartner extends React.Component {
  render() {
    const converter = new Showdown.Converter()

    return (
      <MDBRow className={`mb-3 align-items-top ${ this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >

        <MDBCol lg={this.props.textCol ? this.props.textCol : '6'} className="mt-5 mt-md-3 mt-lg-0 mb-4">
        {this.props.title && (
          <h3 className={ this.props.titleClass ? this.props.titleClass : 'font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium' } >
            {this.props.title}
          </h3>
          )}

            <div
              className="font-w-400 text-medium divlink pr-lg-3"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.description) }}
            />   
               
        </MDBCol>
        <MDBCol lg={this.props.imageCol ? this.props.imageCol : '6'} className="text-lg-center text-sm-left order-first order-md-1">
          <GatsbyImage
            image={this.props.image}
            alt={this.props.alt}
            className={this.props.imageClass ? this.props.imageClass: "rounded innovation-box"}
          />
        </MDBCol>        
      </MDBRow>
    )
  }
}

export default ColumnAlternatingPartner